import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import ContentHero from '../components/ContentHero'
import InformationCard from '../components/InformationCard'
import { graphql } from 'gatsby'
import { compact } from 'lodash'

const Benefits = props => {
  const { data } = props
  console.log(data)
  return (
    <Layout>
      <Hero
        title="Mitgestalter"
        subtitle="Werte zu schaffen ist unsere Leidenschaft"
        img={data.hero.childImageSharp}
      />
      <div className="container">
        <ContentHero
          title="Deine Benefits bei der colenio"
          subtitle="Wir möchten, dass du dich rundum wohl fühlst!"
        />

        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <InformationCard
                isSmall={true}
                title="Work-Life-Balance"
                img={data.family.childImageSharp}
              />
            </div>
            <div className="column is-one-quarter">
              <div className="text is-size-5">
                <strong>Vereinbarkeit von Beruf und Familie: </strong>
                <br></br>Bei uns ist eine gute Work-Life-Balance garantiert.
              </div>
            </div>
            <div className="column is-one-quarter">
              <InformationCard
                isSmall={true}
                title="Arbeitszeit"
                img={data.time.childImageSharp}
              />
            </div>
            <div className="column is-one-quarter">
              <div className="text is-size-5">
                <strong>Flexible Arbeitszeiten:</strong>
                <br></br>Egal ob Frühaufsteher oder Langschläfer. Dein Tag
                beginnt wann es dir am besten passt.
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column is-one-quarter">
              <div className="text is-size-5">
                <strong>
                  Moderne Smartphone und IT-Ausstattung nach Wunsch:
                </strong>
                <br></br>Alles nach Wahl, was Du brauchst bekommst Du.
              </div>
            </div>
            <div className="column is-one-quarter">
              <InformationCard
                isSmall={true}
                title="Ausstattung"
                img={data.equipment.childImageSharp}
              />
            </div>
            <div className="column is-one-quarter">
              <div className="text is-size-5">
                <strong>Home-Office und mobiles Arbeiten:</strong>
                <br></br>Du arbeitest wo du willst. Egal ob im Büro, zuhause
                oder irgendwo dazwischen.
              </div>
            </div>
            <div className="column is-one-quarter">
              <InformationCard
                isSmall={true}
                title="Homeoffice"
                img={data.development.childImageSharp}
              />
            </div>
          </div>

          <div className="columns">
            <div className="column is-one-quarter">
              <InformationCard
                isSmall={true}
                title="Freiheit"
                img={data.freedom.childImageSharp}
              />
            </div>
            <div className="column is-one-quarter">
              <div className="text is-size-5">
                <strong>Freiheit</strong>
                <br></br>Die Freiheit und auch die Verantwortung, das zu tun,
                was Du für richtig hältst.
              </div>
            </div>
            <div className="column is-one-quarter">
              <InformationCard
                isSmall={true}
                title="Weiterbildung"
                img={data.education.childImageSharp}
              />
            </div>
            <div className="column is-one-quarter">
              <div className="text is-size-5">
                <strong>Fachspezifische Weiterbildung:</strong>
                <br></br>Mit einem jährlichem Budget suchst du dir deine nächste
                Weiterbildung aus.
              </div>
            </div>
          </div>
          <br></br>
          <strong>weitere Benefits:</strong>
          <br></br>
          <br></br>
          <ul>
            <li>
              <strong>unbefristeter Arbeitsvertrag: </strong>Wir stellen
              niemanden für ein konkretes Projekt ein, sondern streben immer
              eine lange Beziehung an.
            </li>
            <li>
              <strong>gute Verkehrsanbindung (Siegburg):</strong> Unsere
              Zentrale in Siegburg ist in wenigen Minuten mit Bus und Bahn
              erreichbar.
            </li>
            <li>
              <strong>Wohlfühlgarantie: </strong>Weil der Wohlfühlfaktor für uns
              nicht nur eine Floskel ist.
            </li>
            <li>
              <strong>Wissenstransfer: </strong>Wissen weitergeben und einander
              helfen ist Teil unserer Kultur.
            </li>
            <li>
              <strong>
                Projektvielfalt, Projekt - Rotation und neueste Technologien:
              </strong>
              Deine Weiterbildung soll ja schließlich nicht umsonst gewesen
              sein.
            </li>
            <li>
              <strong>Selbstorganisation & echtes Mitgestaltungsrecht: </strong>
              Alle haben eine Stimme, auch Du.
            </li>
            <li>
              <strong>Kurze Entscheidungswege und flache Hierarchien: </strong>
              Bei uns findest du keine langen und komplizierten Prozesse.
            </li>
            <li>
              <strong>Große Gestaltungsmöglichkeiten: </strong>Wir sind sind
              nicht nur offen für neue Ideen, sondern erwarten diese auch.
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default Benefits

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "team.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
    family: file(relativePath: { eq: "familie-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 400, width: 400)
      }
    }
    time: file(relativePath: { eq: "uhr-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 400, width: 400)
      }
    }
    equipment: file(relativePath: { eq: "portfolio2-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 400, width: 400)
      }
    }
    development: file(relativePath: { eq: "development-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 400, width: 400)
      }
    }
    freedom: file(relativePath: { eq: "klima-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 400, width: 400)
      }
    }
    education: file(relativePath: { eq: "weiterbildung-color.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 400, width: 400)
      }
    }
  }
`
